.launchbutton
{
    display:flex;
    flex-direction: column;
    background-color: #252525;
    height: 35px;
    position:absolute;
    width: 267px;
    color: #fefefe;
    font-family: "Gilmer Medium-Regular", Helvetica;
    font-size: 20.1px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-left: 171px;
    margin-top:440px;
    transition: background-color 0.3s ease, color 0.3s ease;
}
.launch-container
{
    height:273.5px;
    width: 51.5px;
    position:absolute;
    top:60%;
    left:11%;
}
.constant-text{
    height: 94px;
    width: 338px;
    left:171px;
    top:375px;


}
.rotating-element-container
{
    position:absolute;
    left: 27.08vw;
    top: 32.41vh; 
    overflow: hidden;
}
.launched{
    background-color: #252525;
    color: #fefefe;
}
.introvector
{
    position:absolute;
    top:310px;
    left:1095px;
}
.arc
{
    position:absolute;
    top:360px;
    left:1035px;
}
.thirdvector
{
    position: absolute;
    top:550px;
    left:133px;
}

.container.second-launch .constant-text,
.container.second-launch .rotating-text,
.container.second-launch .launch-container,
.container.second-launch .cnslvector,
.container.second-launch .maskvector,
.container.second-launch .maskvector1{
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
}
.container.second-launch .arc,
.container.second-launch .intro,
.container.secong-launch .vectors{
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.5s ease-in-out;
}




.vectorimage
{
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

}
.cnslvector
{
    
    position: absolute;
    top: 0px;  
    right: 0px; 
    z-index: 1000;  /* Ensure it appears on top of other elements */
    transform: scale(0.94);
    transform-origin:top;
    
   
}
.vectorimages
{
   position: absolute;
   top: 0px;
   right: 0px;
   transform-origin: top;
   transform: scale(0.9);
   
}
.masked
{
    position: absolute;
    top:20%;
    transform: translateX(-10%)
}
.rotating-text
{
   
    position: absolute;
    top: 48%;
    right:45%;
    display: flex;
    transition: transform 0.6s ease-out;
    
    
}

.maskvector
{
    position:absolute;
    top:75%;
    right:10%;
    
    
    
    
}
.mask1
{
    position:absolute;
    transform:translateX(-50%);
    top:20%;
    
}
.sideel
{
    position: absolute;
    top: 330px;
    left:1200px;
}
.first-launch-vector
{
    position: absolute;
    top:5%;
    left:80%;
}
.disappearing-vector
{
    position: absolute;
    top:50%;
    left:80%;
}
.sideupper
{
    position: absolute;
    top: 20%;
    left:80%;
}
.sideup
{
    position: absolute;
    top: 20%;
    left:700px;

}
.sidelow
{
    position: absolute;
    top: 72%;
    left:93%;
}
.lsidelower
{
    position: absolute;
    top: 70%;
    left:80%;
}
.masksidelow
{
    position: absolute;
    top: 88%;
    left:79%;
}
.masksideup
{
    position: absolute;
    top:74%;
    left:69%;
}
.lmask{
    position: absolute;
    top:19.5%;
    left:3.5%;

}
.heading
{
    position:absolute;
    top:2%;
    color: #252525;
    font-family: "Gilmer Medium-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left:5%;
}
.dotvector
{
    position:absolute;
    top:100px;
    left:1269px;
}
.cornervector
{
    position:absolute;
    top:100%;
    left:90%;
}
.vectors
{
    position:absolute;
    top:4%;
    left:3.5%;
    opacity: 90%;
}
.vectors1c
{
    position: absolute;
    
    
    transform-origin:center;
}
.constant-text
{
    position: absolute;
    font-family: "Gilmer Medium-Regular";
    font-size: 22px;
    font-weight: 10;
    position: absolute;
    top: 50%;
    right:78%;
    text-align: left;


}
.container
{ 
    
    background-color: #fefefe;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.play-word {
    display: flex;
  align-items: baseline;
  margin-top: 60%;
  margin-left:-40%;
  
    
  }
  
  .reality-word {
    

    display: flex;
    align-items: baseline;
    margin-top: 38%;
    margin-left:-0.95%;
    
  }

  
  .gaming-word {
    display: flex;
    align-items: baseline;
    margin-top:34%;
    margin-left:15%;
    
  }

  .launched body,
.launched .container {
  background-color: #252525; 
  color: #fefefe; 
  
}




  .launched h1, .launched .constant-text, .launched .rotating-text {
    color: #fefefe; 
  }
  
  html, body {
    height: 100%;
  }
  .play-word.animate {
    animation: fadeSlideUp 3s ease-out forwards;
  }
  
  .reality-word.animate {
    animation: fadeSlideDown 3s ease-out forwards;
  }
  
  .gaming-word.animate {
    animation: fadeSlideUp 3s ease-out forwards;
  }
  
  /* Keyframes for sliding up and down with ease-out effect */
  @keyframes fadeSlideUp {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeSlideDown {
    0% {
      opacity: 0;
      transform: translateY(-20%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: 20px;
    }
  
    .rotating-text img {

      margin-top:29%;
      margin-left:60%;
      width:55%;
    }
  
    .launch-container img {
      width:450%;
      position:absolute;
      top:5%;
      left:-85%;

      /* Make button smaller on mobile */
    }
  
    .sideel img, .sidem img, .sideup img, .sidelow img {
      width: 50px; /* Scale down side elements */
    }
  
    /* Adjust text and image sizes */
    .constant-text img {
      width:38%;
      margin-top:15%;
      margin-left:-50%;
     
    }
  
    .cross img {
      width: 80%;
      margin: 10px 0;
    }
  
    .vectors img {
      width: 90%;
    }
  
    /* Responsive masks and vectors */
    .maskvector img, .maskvector1 img {
      width: 70%;
    }
  
    /* Adjust vector container for small screens */
    .cnslvector img {
      width:100%;
      transform-origin:top;
      margin-top:20%;
      margin-left:45%;
      
  }
}
  
  /* Media Queries for Tablets */
  @media (min-width: 768px) and (max-width: 1400px) {
    .container {
      padding: 40px;
    }
    .constant-text img{
        width:92%;
        position: absolute;
        top:34%;
        left:-45%;
    }
    
  
    .rotating-text img {
      width: 200%;
      position: absolute;
      top: 52%;
      left: 40%;
    }
  
    .launch-container img {
      width: 350px;
    }
  
    .cross img, .maskvector img, .maskvector1 img {
      width: 80%;
    }
  }




  
  